import React, { useState, useEffect } from "react"
import { Box, Container, Grid, Typography } from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import Page from "../components/Global/Page"
import ActivateUser from "../components/Cred/ActivateUser"
import CheckActivationCredentials from "../components/Cred/CheckActivationCredentials"
import { useLocation } from "@reach/router"
import gql from "graphql-tag"
import { useMutation } from "@apollo/client"

import { navigate } from "gatsby-link"

const Activate = ({ classes }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const location = useLocation()
  var searchParams = new URLSearchParams(location.search)
  var token = searchParams.getAll("act").toString()

  useEffect(() => {
    if (isLoggedIn) {
      handleValidate()
    }
  }, [isLoggedIn])
  const [handleValidate, { data, loading }] = useMutation(VALIDATE_MUTATION, {
    variables: {
      formToken: token,
    },
    onCompleted: data => {
      const userData = data.activateStaffUser
      if (userData.manager) {
        navigate("./manager")
      } else if (userData.staff) {
        navigate("./staff")
      } else return <div>Something went wrong</div>
    },
  })
  return (
    <Page className={classes.root} title="Activate">
      <Container component="main" maxWidth="xs" height="100%">
        {isLoggedIn ? (
          <CheckActivationCredentials token={token} isLoggedIn={isLoggedIn} />
        ) : (
          <ActivateUser isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
        )}
      </Container>
    </Page>
  )
}

const styles = theme => ({
  root: {},
})
const VALIDATE_MUTATION = gql`
  mutation ($formToken: String!) {
    activateStaffUser(formToken: $formToken) {
      user {
        id
        isVerified
        staff
        manager
      }
    }
  }
`
export default withStyles(styles)(Activate)
