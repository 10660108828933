import React, { useState } from "react"
import { Box, Grid, Typography } from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"

const CheckActivationCredentials = ({ classes, token }) => {
  return <div>One moment please...</div>
}

const styles = theme => ({
  root: {},
})

export default withStyles(styles)(CheckActivationCredentials)
