import React, { useState } from "react"
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { Alert } from "@material-ui/lab"
import LockOutlinedIcon from "@material-ui/icons/LockOutlined"
import { Link } from "gatsby"
import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import { useSiteMetadata } from "../Global/hooks/useSiteMetadata"

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

export default function ActivateUser({ isLoggedIn, setIsLoggedIn }) {
  const classes = useStyles()
  const { sbuId } = useSiteMetadata()
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [warning, setWarning] = useState("")

  const [handleLogin, { data, loading }] = useMutation(
    ACTIVATION_LOGIN_MUTATION,
    {
      variables: {
        password: password,
        email: email,
      },
      onCompleted: data => {
        console.log(data)
        setIsLoggedIn(true)
      },
    }
  )

  const handleSubmit = () => {
    handleLogin()
  }
  return (
    <div className={classes.paper}>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Sign In
      </Typography>
      <form
        className={classes.form}
        noValidate
        onSubmit={event => {
          event.preventDefault()
          handleSubmit(event)
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={email}
              onChange={event => setEmail(event.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              value={password}
              autoComplete="current-password"
              onChange={event => setPassword(event.target.value)}
            />
          </Grid>
        </Grid>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          Sign In
        </Button>
        {/* <Grid container justify="flex-end">
          <Grid item>
            <Link to="/staff" variant="body2">
              Already have an account? Sign in
            </Link>
          </Grid>
        </Grid> */}
        <Grid container justify="flex-end">
          <Grid item>
            <div>{password && password}</div>
          </Grid>
          <Grid item>
            {warning.length > 3 && (
              <Alert onClose={() => setWarning("")} severity="warning">
                {warning}
              </Alert>
            )}
          </Grid>
        </Grid>
      </form>
    </div>
  )
}
const ACTIVATION_LOGIN_MUTATION = gql`
  mutation tokenAuth($email: String!, $password: String!) {
    tokenAuth(email: $email, password: $password) {
      token
      __typename
    }
  }
`
